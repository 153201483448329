import moment from 'moment';

const DATE_TIME_HISTORY_VIEW = 'DD.MM.YY HH:mm';
const DATE_TIME_HISTORY_REQUEST_FORMAT = 'YYYY-MM-DD HH:mm';

export const formatHistoryDateTimeToView = date => {
  if (!date) return '-----';
  return moment(date).format(DATE_TIME_HISTORY_VIEW);
};

export const prepareDateFilterForRequest = payload => {
  if (payload instanceof Array && payload.length > 1) {
    const firstEl = moment(payload[0]).format(DATE_TIME_HISTORY_REQUEST_FORMAT);
    const secondEl = moment(payload[1]).format(DATE_TIME_HISTORY_REQUEST_FORMAT);
    const isFirstArrElementErlier = moment(firstEl).isBefore(secondEl);
    if (isFirstArrElementErlier) {
      return [
        {
          field: 'date_from',
          value: firstEl,
        },
        {
          field: 'date_to',
          value: secondEl,
        },
      ];
    } else {
      return [
        {
          field: 'date_from',
          value: secondEl,
        },
        {
          field: 'date_to',
          value: firstEl,
        },
      ];
    }
  }
  console.error('Payload wasnt complete');
  return [];
};

export const prepareDateFilterTimestampForRequest = payload => {
  if (payload instanceof Array && payload.length > 1) {
    const firstEl = moment(payload[0]).format('X');
    const secondEl = moment(payload[1]).format('X');
    const isFirstArrElementErlier = firstEl < secondEl;
    if (isFirstArrElementErlier) {
      return [
        {
          field: 'from_timestamp',
          value: firstEl,
        },
        {
          field: 'till_timestamp',
          value: secondEl,
        },
      ];
    } else {
      return [
        {
          field: 'from_timestamp',
          value: secondEl,
        },
        {
          field: 'till_timestamp',
          value: firstEl,
        },
      ];
    }
  }
  console.error('Payload wasnt complete');
  return [];
};

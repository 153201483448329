<script>
import { Doughnut } from 'vue-chartjs';

const DOUGHNUT_BORDER_COLORS = ['#29B2FA', '#FF556D', '#32E1C8', '#DA94D9', '#FD7146', '#806DFA', '#F2C52D'];
export default {
  extends: Doughnut,
  props: {
    dataset: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: {
        responsive: true,
        legend: {
          display: true,
          position: 'right',
          align: 'center',
          fullWidth: false,
          labels: {
            boxWidth: 10,
            fontColor: 'white',
            fontFamily: 'Roboto',
            fontSize: 10,
          },
        },
        layout: {
          padding: {
            left: 0,
            right: 50,
            top: 0,
            bottom: 0,
          },
        },
        maintainAspectRatio: false,
        animation: {
          animateRotate: false,
        },
        cutoutPercentage: 90,
      },
    };
  },
  watch: {
    dataset: {
      handler() {
        this.mountChart();
      },
    },
  },
  methods: {
    mountChart() {
      this.renderChartAction();
    },
    renderChartAction() {
      this.renderChart(
        {
          labels: this.dataset.labels,
          datasets: [
            {
              backgroundColor: DOUGHNUT_BORDER_COLORS,
              data: this.dataset.data,
              borderWidth: 0,
            },
          ],
        },
        this.options,
      );
    },
  },
  mounted() {
    this.mountChart();
  },
};
</script>

<template>
  <div class="InlineSelectWrapper" :class="{ 'InlineSelectWrapper--inputSelect': type === 'inputSelect' }">
    <label v-if="label">{{ label }}</label>
    <v-select
      class="InlineSelect_root"
      :class="{ 'InlineSelect_root--inputSelect': type === 'inputSelect' }"
      :name="name"
      :value="value"
      item-text="value"
      item-value="key"
      :hide-details="hideDetails"
      :items="items"
      :label="placeholder"
      attach
      solo
      @change="onChange"
    ></v-select>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: [ String, Boolean ],
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Object, Number],
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    items: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    onChange(item) {
      this.$emit('change', {
        field: this.name || '',
        value: item,
      });
    },
  },
};
</script>

<style lang="scss">
@import './InlineSelect.scss';
</style>

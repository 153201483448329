<template>
  <section class="Balances_root">
    <AppSection title="Balances">
      <div class="Balances_wrapper" slot="content">
        <div class="Balances_charts">
          <div v-if="doughnutComputedDataset" class="Balances_chart">
            <div class="Balances_info-row Balances_info-row--blue">
              <SvgIcon
                icon="iconCoins"
              ></SvgIcon>
              <span>{{ selectedCurrencyTotalBalance }}</span>
              <InlineSelect
                class="Balances_chart-select"
                :value="selectedCurrency"
                :items="providersSelectCurrencies"
                @change="onProviderCurrencyChange"
              />
            </div>
            <DoughnutChart :width="150" :height="150" :dataset="doughnutComputedDataset" />
          </div>
          <div class="Balances_chart" v-if="doughnutTotalDataset">
            <div class="Balances_info-row">
              <div class="Balances_info-label">Estimated value: {{ totalEstimatedValue }} USD</div>
            </div>
            <DoughnutChart :width="150" :height="150" :dataset="doughnutTotalDataset" />
          </div>
        </div>
        <div class="Balances_tabel">
          <template>
            <v-data-table
              :headers="headers"
              :items="computedProviderBalances"
              :items-per-page="5"
              class="elevation-1"
              :disable-pagination="true"
              hide-default-footer
              hide-default-header
            >
              <template slot="header" :headers="headers">
                <thead class="v-data-table-header">
                  <tr>
                    <th class="text-start" v-for="header in headers" :key="header.value">
                      <span v-if="header.value === 'name'">
                        <span class="balances_filter-wrapp">
                          <select
                            class="balance_filter-select"
                            name="provider_name"
                            :value="selectedProvider"
                            @change="handleUpdateProviderFilter"
                          >
                            <option key="all">ALL PROVIDERS</option>
                            <option :value="provider.key" v-for="provider in providerNamesFilter" :key="provider.value">
                              {{ provider.value }}
                            </option>
                          </select>
                        </span>
                      </span>
                      <span v-else>{{ header.text }}</span>
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div
                  :class="[
                    'Balances_table-btn',
                    {
                      ['Balances_table-btnDisabled']: item.action,
                    },
                  ]"
                >
                  transfer
                </div>
              </template>
            </v-data-table>
          </template>
        </div>
      </div>
    </AppSection>
  </section>
</template>

<script>
import { isEmpty } from 'ramda';
import { mapState, mapGetters, mapActions } from 'vuex';
import AppSection from '@/components/_common/AppSection/AppSection';
import InlineSelect from '@/components/_common/FormElements/InlineSelect/InlineSelect';
import { formatHistoryDateTimeToView } from '../History/HistoryTable/_helpers/dateTimeFormatter';
import DoughnutChart from './DoughnutChart/DoughnutChart';
import ProvidersEmpty from '../ProvidersEmpty/ProvidersEmpty';
import {roundingCurrency} from "@/utils/helpers";
import SvgIcon from "@/components/_common/SvgIcon";

export default {
  components: {
    SvgIcon,
    AppSection,
    InlineSelect,
    DoughnutChart,
    ProvidersEmpty,
  },
  data() {
    return {
      headers: [
        { text: 'provider', value: 'name' },
        { text: 'last update', value: 'update' },
        { text: 'currency', value: 'currency' },
        { text: 'balance', value: 'balance' },
        { text: 'action', value: 'action', sortable: false },
      ],
      selectedCurrency: '',
      showProviderSelect: false,
      selectedProvider: 'ALL PROVIDERS',
    };
  },
  watch: {
    isBalancesLoaded: {
      handler(val) {
        if (val) this.setCurrencyFromProviders();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('balance', ['isBalancesPending', 'isBalancesLoaded']),
    ...mapState('providers', ['providersBalances', 'userProviders']),
    ...mapGetters('providers', ['userProviders']),
    totalEstimatedValue() {
      return roundingCurrency({ currency: 'USD', currencyAmount: Object.keys(this.providersBalances).reduce((acc, curr) => {
          if (this.providersBalances[curr] && this.providersBalances[curr].total_in_usd) {
            acc += this.providersBalances[curr].total_in_usd;
          }
          return acc;
        }, 0) 
      });
    },
    selectedCurrencyTotalBalance() {
      if (this.selectedCurrency) {
        return this.providersBalances[this.selectedCurrency] && this.providersBalances[this.selectedCurrency].total;
      }
      return 0;
    },
    providersSelectCurrencies() {
      return Object.keys(this.providersBalances);
    },
    doughnutComputedDataset() {
      return this.computedProviderBalances
        .filter(item => item.currency === this.selectedCurrency)
        .reduce(
          (acc, curr) => {
            acc.labels.push(curr.name);
            acc.data.push(curr.balance);
            return acc;
          },
          {
            labels: [],
            data: [],
          },
        );
    },
    doughnutTotalDataset() {
      return this.computedProvidersTotalBalances.reduce(
        (acc, curr) => {
          acc.labels.push(`${curr.currency} ${curr.balance}`);
          acc.data.push(curr.balance);
          return acc;
        },
        {
          labels: [],
          data: [],
        },
      );
    },
    computedProvidersTableData() {
      console.log(this.computedProviderBalances)
      if (this.selectedProvider === 'ALL PROVIDERS') return this.computedProviderBalances;
      return this.computedProviderBalances.filter(item => item.name === this.selectedProvider);
    },
    computedProviderBalances() {
      if (!isEmpty(this.providersBalances)) {
        return Object.keys(this.providersBalances).reduce((acc, curr) => {
          if (!isEmpty(this.providersBalances[curr] && this.providersBalances[curr].payment_accounts)) {
            const currencyProviders = this.providersBalances[curr].payment_accounts;
            Object.keys(currencyProviders).forEach(item => {
              const updateItem = (currencyProviders[item] && currencyProviders[item].last_update) || '';
              const name = this.getProviderNameById(item)
              acc.push({
                name: name,
                update: formatHistoryDateTimeToView(updateItem),
                balance: currencyProviders[item].total,
                currency: curr,
                action: true,
              });
            });
          }
          return acc;
        }, []);
      }
      return [];
    },
    computedProvidersTotalBalances() {
      if (!isEmpty(this.providersBalances)) {
        return Object.keys(this.providersBalances).reduce((acc, curr) => {
          if (this.providersBalances[curr] && this.providersBalances[curr].total) {
            acc.push({
              currency: curr,
              balance: this.providersBalances[curr].total,
            });
          }
          return acc;
        }, []);
      }
      return [];
    },
    providerNamesFilter() {
      // console.log(this.userProviders)
      // let results = [{ key: '', value: 'ALL PROVIDERS' }]
      let results = []
      if (!isEmpty(this.userProviders)) {
        Object.keys(this.userProviders).forEach(item=>{
          if (!results.some(resultItem=>resultItem.value === this.userProviders[item].provider)) {
            results.push({
              key: this.userProviders[item].provider,
              value: this.userProviders[item].provider,
            });
          }
        });
      }
      return results;
    },
    // old computed
    // providerNamesFilter() {
    //   if (!isEmpty(this.userProviders)) {
    //     return Object.keys(this.userProviders).reduce((acc, curr) => {
    //       if (!isEmpty(this.userProviders[curr])) {
    //         const currencyProviders = Object.keys(this.userProviders[curr]);
    //         currencyProviders.forEach(item => {
    //           acc.push(item);
    //         });
    //       }
    //       return acc;
    //     }, []);
    //   }
    //   return [];
    // },
  },
  methods: {
    ...mapActions('providers', ['apiGetUserProviders']),
    getProviderNameById(id) {
      for (const index in this.userProviders) {
        if (this.userProviders[index].id == id) return this.userProviders[index].name
      }
    },
    handleUpdateProviderFilter(event) {
      // console.log('value', event.target.value);
      this.selectedProvider = event.target.value;
    },
    onProviderCurrencyChange({ value }) {
      this.selectedCurrency = value;
    },
    setCurrencyFromProviders() {
      if (this.providersSelectCurrencies.length) {
        if (this.providersSelectCurrencies.includes('UAH')) {
          this.selectedCurrency = 'UAH';
        } else {
          this.selectedCurrency = this.providersSelectCurrencies[0];
        }
      }
    },
  },
  mounted() {
    this.apiGetUserProviders();
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';
@import './Balances.scss';

.Balances_info-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin: 0 auto 15px;
  padding-left: 15px;
  &--blue {
    color: #2af3f3;
    background: rgba(#2af3f3, 0.06);
    border-radius: 2px;
  }
  img {
    flex: 0 1 16px;
    width: 16px;
    margin-right: 5px;
  }
  span {
    display: block;
    font-size: 18px;
    line-height: 44px;
    padding: 0 10px;
  }
  .Balances_chart-select {
    max-width: 100px;
  }
  .Balances_info-label {
    line-height: 46px;
    font-size: 18px;
  }
}

.balance_filter-select {
  outline: none;
  box-shadow: none;
  color: white;
  option {
    background: #102032;
  }
}
</style>
